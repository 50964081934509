import React from 'react';
import PropTypes from 'prop-types';
import 'bootstrap/dist/css/bootstrap.min.css';
import './header.css';
import Nav from 'components/header/nav';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Container,
} from 'reactstrap';

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: props.title,
      collapsed: true,
    };
  }

  toggleNavbar = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  render() {
    return (
      <div id="headerBar">
        <Container>
          <Navbar style={{ paddingTop: '0px' }} light expand="md">
            <NavbarBrand href="/" style={{ fontFamily: 'Aller' }}>
              <img id="navLogo" src={'/gg-logo.png'} alt="gg" />
              {this.state.title}
            </NavbarBrand>
            <NavbarToggler
              aria-label="Toggle navigation"
              aria-expanded={!this.state.collapsed}
              onClick={this.toggleNavbar}
              className="mr-2"
            />
            <Collapse isOpen={!this.state.collapsed} navbar>
              <Nav />
            </Collapse>
          </Navbar>
        </Container>
      </div>
    );
  }
}

Header.propTypes = {
  title: PropTypes.string.isRequired,
};

export default Header;
